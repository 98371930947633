<template>
  <div class="hello login_body">

    <div class="login_div" id="loginDiv">

      <div class="titleHead">学为双师</div>

      <div class="login_con">
        <div class="inputDiv">
          <div class="inputHead">账号</div>
          <input type="text" id="user" v-model="userName" placeholder="请输入用户名"/>
        </div>
        <div class="inputDiv">
          <div class="inputHead">密码</div>
          <input type="password" id="password" @keydown="goLogin" v-model="password" placeholder="请输入密码"/>
        </div>
        
      </div>
      <div class="login_btn" @click="btnLogin">登录</div>
      <!-- <p class="login_lx">忘记密码请联系400-001-7799</p> -->
    </div>

    <ssAlert ref="childAlert"></ssAlert>
    <ssLoading ref="childLoading"></ssLoading> 

  </div>
</template>


<script>

import ssAlert from "@/components/commonVue/Alert";
import ssLoading from "@/components/commonVue/Loading";


export default {
  name: "index",
  components: {
    ssAlert,
    ssLoading
  },
  data() {
    return {
      path: this.newCommon["publicDef"]["newPath"],
      loading: {},
      userName: '',
      password: '',
    };
  },
  props: {

  },
  mounted() {
    document.title = "学为双师-学生端-登录"
  },
  methods: {
    goLogin(e){
      if (e.keyCode == 13) {
        if(!this.userName){
          this.$refs.childAlert.show("请输入账号");
          return false;
        }

        if(!this.password){
          this.$refs.childAlert.show("请输入密码");
          return false;
        }

        this.$refs.childLoading.show();

        //登录之前清空所有浏览器缓存
        localStorage.clear();


        this.axios({
          url:  this.path + "api/clientLogin",
          method:"post",
          params: {
            account: this.userName.trim(),
            pwd: this.password.trim()
          }
        }).then( (response)=> {
            let sessDta = response.data;
            this.$refs.childLoading.hide();
            if(sessDta.code == 200){
              localStorage.setItem("nickName", sessDta["data"]["schoolName"]);
              localStorage.setItem("account", sessDta["data"]["account"]);
              localStorage.setItem("token", sessDta["data"]["token"]);
              localStorage.setItem("classId", sessDta["data"]["classId"]);
              this.$router.push('/classList');


            }else {
              this.$refs.childAlert.show(sessDta.msg);
            }
            
        }).catch( (error)=> {
            this.$refs.childLoading.hide();
            this.$refs.childAlert.show("网络异常");
        });


      }

    },
    btnLogin(){
      if(!this.userName){
        this.$message.error('请输入账号');
        return false;
      }

      if(!this.password){
        this.$message.error('请输入密码');
        return false;
      }

      //登录之前清空所有浏览器缓存
      localStorage.clear();

      this.$refs.childLoading.show();

      this.axios({
        url:  this.path + "api/clientLogin",
        method:"post",
        params: {
          account: this.userName.trim(),
          pwd: this.password.trim()
        }
      }).then( (response)=> {
          let sessDta = response.data;
          this.$refs.childLoading.hide();
          if(sessDta.code == 200){
            localStorage.setItem("nickName", sessDta["data"]["schoolName"]);
            localStorage.setItem("account", sessDta["data"]["account"]);
            localStorage.setItem("token", sessDta["data"]["token"]);
            localStorage.setItem("classId", sessDta["data"]["classId"]);
            this.$router.push('/classList');
          
          }else {
            this.$refs.childAlert.show(sessDta.msg);
          }
          
          
      }).catch( (error)=> {
        this.$refs.childLoading.hide();
        this.$refs.childAlert.show("网络异常");
      });

    },


  },
};
</script>

<style scoped>

.login_body {
  width: 100%;
  height: 100%;
  background: url("../assets/teaImg/loginbgimg.png") no-repeat;
  background-size: 100% 100%;
}

.login_div{width:400px;height:400px;position:absolute;box-shadow:0 0 8px #E0E3E7;background:#FFF;top: 0;right: 0;left: 0;bottom: 0;margin: auto;padding: 20px 20px;box-sizing: border-box;}

.titleHead {
	font-size: 20px;
	font-weight: 700;
	color: #333;
	text-align: center;
}
.inputDiv {
	width: 90%;
	margin: 40px auto;
	border-bottom: 1px solid #C5C5C5;
	box-sizing: border-box;
  text-align: left;
}
.inputDiv:hover {
	border-bottom: 1px solid #2F5AFF;
}
.inputHead {
	width: 100%;
	padding-left: 10px;
	font-size: 16px;
	font-weight: 700;
	color: #333;
	box-sizing: border-box;
	margin-bottom: 10px;
}
.inputDiv input{
	width:100%;
	padding:0 0 0 10px;
	font-size:12px;
	height:30px;
	border:0;
	outline:none;
	color: #666;
}

.login_btn{width:90%;background:#2F5AFF;height:40px;line-height:40px;font-size:16px;font-weight:bold;text-align:center;cursor:pointer;color:#FFF;letter-spacing:2px;border-radius:6px;margin:40px auto 0;letter-spacing: 2px;}
.login_btn:hover{background:#2F9CFF;}






</style>
