<template>
  <div class="hello" style="width: 100%;height: 100%;background-color:#222736;">

    <el-container v-show="isShow">
      <div id="video_wrap" style="margin:5px auto 0;">
      </div>
    </el-container>

     <!--遮罩层-->
    <div class="livezhezhao" id="livezhezhao1"></div>

    <!-- 选择摄像头弹窗 -->
    <div class="cameraChoseDiv" id="cameraChoseDiv1">

      <div style="font-size: 16px;font-weight: 700;color: #333;text-align: center;margin-bottom:10px">选择设备</div>
      <p style="color:red;text-align:center;padding-bottom:10px;" id="jcWord">检测视频设备中...</p>
      <div class="choseDiv cameraDiv" id="shesDiv">
        <span>摄像头</span>
        <el-select v-model="cameraId" @change="changeCamerTest" placeholder="请选择">
            <el-option
              v-for="item in devices.camera"
              :key="item.deviceId"
              :label="item.label"
              :value="item.deviceId">
            </el-option>
        </el-select>
      </div>

      <div class="choseDiv micDiv" id="maisDiv" style="display:none;">
        <span>麦克风</span>
        <el-select v-model="micId" @change="changeMicTest" placeholder="请选择">
            <el-option
              v-for="item in devices.mic"
              :key="item.deviceId"
              :label="item.label"
              :value="item.deviceId">
            </el-option>
        </el-select>
      </div>

      <div v-on:click="choseCamerBtn" class="cameraBtn" style="display:none;" id="deviseOKBtn">确定</div>
      <div v-on:click="gojcMai" class="cameraBtn" id="videoOKBtn">下一步</div>
      <div style="width:360px;height:200px;margin-top:20px;position:relative;" :class="{'bgcBlick': isBlack}" id="testVideo"></div>
      
    </div>

    <!--遮罩层视频中选择-->
    <div class="livezhezhao" id="livezhezhao2" style="display:none;"></div>

    <!-- 选择摄像头弹窗 -->
    <div class="cameraChoseDiv" id="cameraChoseDiv2" style="height:230px;display:none;">

      <div style="font-size: 16px;font-weight: 700;color: #333;text-align: center;margin-bottom:10px">选择设备</div>
      <div class="choseDiv cameraDiv">
        <span>摄像头</span>
        <el-select v-model="cameraId" @change="changeCamer" placeholder="请选择">
            <el-option
              v-for="item in devices.camera"
              :key="item.deviceId"
              :label="item.label"
              :value="item.deviceId">
            </el-option>
        </el-select>

      </div>

      <div class="choseDiv micDiv">
        <span>麦克风</span>
        <el-select v-model="micId" @change="changeMic" placeholder="请选择">
            <el-option
              v-for="item in devices.mic"
              :key="item.deviceId"
              :label="item.label"
              :value="item.deviceId">
            </el-option>
        </el-select>
      </div>

      <div v-on:click="choseCamerBtn2" class="cameraBtn">确定</div>
      
    </div>

    <!-- 提示弹窗 -->
    <div class="tsDiv">
      {{ tsText }}
    </div>

    <!-- 问题反馈弹窗 -->
    <div class="questionDiv">

      <div style="font-size: 16px;font-weight: 700;color: #333;text-align: center;margin: 10px auto">问题描述</div>

      <div class="messageDiv">

        <textarea placeholder="最多50字" type="text" v-model="messageContent" maxlength="50"></textarea>

      </div>

      <div class="questionBtnDiv">
        <div v-on:click="cancleSend" class="cancleMessage">取消</div>
        <div v-on:click="sendSure" class="sendMessage">发送</div>
      </div>
      
    </div>

    <!-- 老师未进入房间 -->
    <div v-if="zwtIndex==0" class="zhanwei">
      <img src="@/assets/teaImg/waittea.png" /><br/><br/>
      <p>等待老师进入教室</p>
    </div>
    <!-- 老师暂时离开教室 -->
    <div v-if="zwtIndex==2" class="zhanwei">
      <img src="@/assets/teaImg/zanlitea.png" /><br/><br/>
      <p>老师暂时离开教室</p>
    </div>
    <!-- 全屏 -->
    <div class="full-screen" v-if="zhezhaoIndex==1" v-on:click="fullscreen"></div>
    <!-- 返回 -->
    <div class="go_back" v-on:click="goBack">返回</div>
    <div class="go_back" v-on:click="changeDevice" style="left:auto;width:170px;right:20px;">选择摄像头/麦克风</div>
    <div class="go_back" v-on:click="messageShow" style="left:auto;width:170px;right:20px;top: 65px;">举手</div>

    

    
    <ssAlert ref="childAlert"></ssAlert>
    <ssLoading ref="childLoading"></ssLoading> 

  </div>
</template>

<script>
import $ from "jquery";
// import rtc from './mixins/rtc.js';
import TRTC from 'trtc-sdk-v5';
import ssAlert from "@/components/commonVue/Alert";
import ssLoading from "@/components/commonVue/Loading";

import TencentCloudChat from '@tencentcloud/chat';
import TIMUploadPlugin from 'tim-upload-plugin';
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';



export default {
  name: 'studentLive',
  components: {
    ssAlert,
    ssLoading,
  },
  data(){
    return {
      path: this.newCommon["publicDef"]["newPath"],
      hideshow: true,
      choseCamShow: true,
      questionShow: false,
      tsDivShow: false,
      xkShow: false,
      isBlack: false,
      users: '',
      token: '',
      courseName: '',
      account: '',
      sdkAppId: '',
      userSig: '',
      roomId: '',
      tsText: '',
      nowSpeakUserId: '',
      //消息
      msgs: [],
      imMsg: {
        common: {},
        custom: {}
      },
      messageContent: '',
      //音视频及设备
      enableCamera: true,
      enableMic: true,
      cameraId:'',
      micId:'',
      cameraIndex: 0,
      micIndex: 0,
      devices: {
        camera: [],
        mic: []
      },
      isShow: false,
      clickNum: 1,
      isPushing: 0, // 是否正在推流
      isPushCamera: 0, // 是否推摄像头流
      zwtIndex: 0,   // 0老师没有进入房间  1正在直播  2 老师暂时离开教室
      zhezhaoIndex: 0,
      jtestNum: 0,
      imReady: false,
      inUserIds: [],
      questionList: []
    }

  },
  props: {

  },
  mounted() {

    this.isShow = true;
    this.accountName = localStorage.getItem("nickName") +"|"+ localStorage.getItem("account");
    this.nickName = localStorage.getItem("nickName");
    this.account = localStorage.getItem("account");
    this.courseName = localStorage.getItem("courseName");
    document.title = localStorage.getItem("courseName")||'学生端直播';
    this.roomId = localStorage.getItem("roomId");
    this.userSig = localStorage.getItem("userSign");
    this.sdkAppId =  '1400370768';

    // var getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia); 
    // getUserMedia.call(navigator, { video: true, audio: true }, function(localMediaStream) { }, function(e) { 
    //   console.log('Reeeejected!', e); 
    // }); 

    navigator.mediaDevices
    .getUserMedia({ video: true, audio: true })
    .then(() => {
      this.getCameraDevices();
      // this.getMicDevices();
    })
    .catch(err => {
      console.log('getUserMedia err', err.name, err.message);
      this.getCameraDevices();
      // handleGetUserMediaError(err);
    });

    // this.getCameraDevices();
    // this.getMicDevices();
    this.trtc = TRTC.create();
    this.trtc.stopLocalVideo();
    this.trtc.stopLocalAudio();
    this.init();

    // $("body").keyup(function () {
    //   if (event.which == 27){
    //     $(".lmBtn").parent().removeClass("fullscreen");
    //   }
    // });
 
  },
  beforeDestroy(){
    this.trtc.stopLocalVideo();
    this.trtc.stopLocalAudio();
  },
  methods: {
    showTip(title, time) {
      this.$message({
        showClose: true,
        message: title,
        type: 'success'
      });
    },
    init(){
      let options = {
        SDKAppID: this.sdkAppId // 接入时需要将0替换为您的即时通信 IM 应用的 SDKAppID
      };
      // 创建 SDK 实例，`TIM.create()`方法对于同一个 `SDKAppID` 只会返回同一份实例
      this.chat = TencentCloudChat.create(options); // SDK 实例通常用 chat 表示

      this.chat.setLogLevel(0); // 普通级别，日志量较多，接入时建议使用
      // this.chat.setLogLevel(1); // release 级别，SDK 输出关键信息，生产环境时建议使用

      // 注册腾讯云即时通信 IM 上传插件
      this.chat.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});

      // 注册腾讯云即时通信 IM 本地审核插件
      this.chat.registerPlugin({'tim-profanity-filter-plugin': TIMProfanityFilterPlugin});

      let promise = this.chat.login({userID: this.account , userSig: this.userSig});
      promise.then((imResponse)=> {
        console.log(imResponse); // 登录成功
        console.log("IM登录成功"); // 登录成功
        this.chat.on(TencentCloudChat.EVENT.SDK_READY, this.onSdkReady);
        if (imResponse.data.repeatLogin === true) {
          // 标识账号已登录，本次登录操作为重复登录。
          // console.log(imResponse.data.errorInfo);
        }
      }).catch(function(imError) {
        console.warn('login error:', imError); // 登录失败的相关信息
      });

      this.chat.on(TencentCloudChat.EVENT.MESSAGE_RECEIVED, (event)=> {
        // 收到推送的单聊、群聊、群提示、群系统通知的新消息，可通过遍历 event.data 获取消息列表数据并渲染到页面
        // event.name - TencentCloudChat.EVENT.MESSAGE_RECEIVED
        // event.data - 存储 Message 对象的数组 - [Message]

        console.log(event)

        let fromUserId = event.data[0].from;
        let text = event.data[0].payload.text;

        var d = new Date();
        var time = `${('0' + d.getHours()).substr(-2)}:${('0' + d.getMinutes()).substr(-2)}:${('0' + d.getSeconds()).substr(-2)}.${('00' + d.getMilliseconds()).substr(-3)}`
        
        if(text=='bizuiall'){   //老师说全部闭麦
          this.enableMic = false;
          this.toggleMic();
          let hasAudioImg = document.getElementById('nowAudioImg');
          if(hasAudioImg){
            hasAudioImg.parentNode.removeChild(hasAudioImg);
          }
        }else if(text.indexOf('iamteacher')!=-1){                 
          let teaName = text.split('|')[1];
          localStorage.setItem('teaUserId', teaName);
          this.sendCustomGroupMsg(null, 'schoolName|'+ this.accountName +'');  
          setTimeout(()=>{
            let teaDiv = document.getElementsByName(teaName)[0];
            if(teaDiv){
              teaDiv.className = 'big_tea';
              teaDiv.parentNode.style.width = '0';
              this.zwtIndex = 1;
            }
          }, 1500);
          this.zhezhaoIndex = 1;
        }else if(text.indexOf('zhangzui')!=-1){    //老师群发让人说话
          if(text.split('|')[1] == this.account){
              this.enableMic = true;
              this.toggleMic();
          }
          if($('#nowAudioImg').length>=1){
            $('#nowAudioImg').remove();
          }
          let nowAudioImg = document.createElement('img');
          nowAudioImg.id = 'nowAudioImg';
          nowAudioImg.src = 'https://xwliveimage.oss-cn-beijing.aliyuncs.com/2023/mai.png';
          // nowAudioImg.src = './img/big-mic-on.png';
          let divName = text.split('|')[1];
          let parentDiv = null;
          setTimeout(()=>{
            parentDiv = document.getElementsByName(divName)[0];
            if(parentDiv.childNodes.length!=1){  //
              nowAudioImg.className = 'img_she_qita';
            }else{
              nowAudioImg.className = 'img_she';
            }
            parentDiv.appendChild(nowAudioImg);
          }, 800);
        }else if(text.indexOf('bizui|')!=-1){   //老师群发让人闭嘴 
          if(text.split('|')[1] == this.account){
            this.enableMic = false;
            this.toggleMic();
          }
          let hasAudioImg = document.getElementById('nowAudioImg');
          if(hasAudioImg){
            hasAudioImg.parentNode.removeChild(hasAudioImg);
          }
        }else if(text.indexOf('stopLive')!=-1){   //下课了

          this.$refs.childAlert.show("下课了",()=>{
            this.$router.push('/classList');  
          });
          this.trtc.exitRoom(); 
          this.trtc.stopLocalVideo();
          this.trtc.stopLocalAudio();
          // 退房成功后，若后续无需使用 trtc 实例，则可以调用 trtc.destroy 方法销毁实例，及时释放相关资源。销毁后的 trtc 实例无法继续使用，需要重新创建新的实例。
          this.trtc.destroy();
          setTimeout(()=>{
              this.$router.push('/classList');  
          },2000)

        }else{  //正常发送文字消息
          this.msgs.push({
            time: time,
            send: fromUserId + '：',
            content: text
          });
        }
        

      });

      this.chat.on(TencentCloudChat.EVENT.NET_STATE_CHANGE, function(event) {
        if(event.data.state == TencentCloudChat.TYPES.NET_STATE_CONNECTING){
          this.showTip('连接中。。。。。。'); 
        }        
        // 网络状态发生改变
        // event.name - TencentCloudChat.EVENT.NET_STATE_CHANGE
        // event.data.state 当前网络状态，枚举值及说明如下：
        //   - TencentCloudChat.TYPES.NET_STATE_CONNECTED - 已接入网络
        //   - TencentCloudChat.TYPES.NET_STATE_CONNECTING - 连接中。很可能遇到网络抖动，SDK 在重试。接入侧可根据此状态提示“当前网络不稳定”或“连接中”
        //   - TencentCloudChat.TYPES.NET_STATE_DISCONNECTED - 未接入网络。接入侧可根据此状态提示“当前网络不可用”。SDK 仍会继续重试，若用户网络恢复，SDK 会自动同步消息
      });
    },
    onSdkReady(){

      // 创建直播群
      this.chat.createGroup({
        groupID: this.roomId,
        type: TencentCloudChat.TYPES.GRP_AVCHATROOM,
        name: this.courseName,
      }).then((evevt)=>{
        console.log(evevt)
        this.chat.joinGroup({ groupID: this.roomId, type: TencentCloudChat.TYPES.GRP_AVCHATROOM }).then(()=>{
          this.imReady = true;
          let teaUserName = localStorage.getItem('teaUserId');
          this.sendCustomGroupMsg(null, 'whoisteacher'); 
          this.sendCustomGroupMsg(null, 'schoolName|'+ this.accountName +'');
          if(!teaUserName){
            let getTeaFn = setInterval(()=>{
              if(localStorage.getItem('teaUserId')){
                window.clearInterval(getTeaFn);
                getTeaFn = null;
                return false;
              }
              this.sendCustomGroupMsg(null, 'whoisteacher'); 
              this.sendCustomGroupMsg(null, 'schoolName|'+ this.accountName +'');                      
            }, 3000)
          }
        });
      }).catch((evevt)=>{
        console.log(evevt)
        this.chat.joinGroup({ groupID: this.roomId, type: TencentCloudChat.TYPES.GRP_AVCHATROOM }).then(()=>{
          this.imReady = true;
          let teaUserName = localStorage.getItem('teaUserId');
          this.sendCustomGroupMsg(null, 'whoisteacher'); 
          this.sendCustomGroupMsg(null, 'schoolName|'+ this.accountName +'');
          if(!teaUserName){
            let getTeaFn = setInterval(()=>{
              if(localStorage.getItem('teaUserId')){
                window.clearInterval(getTeaFn);
                getTeaFn = null;
                return false;
              }
              this.sendCustomGroupMsg(null, 'whoisteacher'); 
              this.sendCustomGroupMsg(null, 'schoolName|'+ this.accountName +'');                      
            }, 3000)
          }
        });
      });


      // this.chat.joinGroup({ groupID: this.roomId }).then(()=>{
      //   this.imReady = true;
      //   let teaUserName = localStorage.getItem('teaUserId');
      //   this.sendCustomGroupMsg(null, 'whoisteacher'); 
      //   this.sendCustomGroupMsg(null, 'schoolName|'+ this.accountName +'');
      //   if(!teaUserName){
      //     let getTeaFn = setInterval(()=>{
      //       if(localStorage.getItem('teaUserId')){
      //         window.clearInterval(getTeaFn);
      //         getTeaFn = null;
      //         return false;
      //       }
      //       this.sendCustomGroupMsg(null, 'whoisteacher'); 
      //       this.sendCustomGroupMsg(null, 'schoolName|'+ this.accountName +'');                      
      //     }, 3000)
      //   }

      // });



      // 该接口默认只会拉取这些资料：群类型、群名称、群头像、最后一条消息的时间。
      // let promise = this.chat.getGroupList();
      // promise.then(function(imResponse) {
      //   console.log(imResponse.data.groupList); // 群组列表
      // }).catch(function(imError) {
      //   console.warn('getGroupList error。。。。。。。。。。。。。。:', imError); // 获取群组列表失败的相关信息
      // });

      // this.sendCustomGroupMsg(null, 'schoolName|'+ this.accountName +'');  

      this.chat.on(TencentCloudChat.EVENT.KICKED_OUT, (event)=>{
        if(event.data.type == 'multipleAccount'){
          this.showTip('用户异地登录，请重新登录'); 
          setTimeout(()=>{
              this.$router.push('/index');  
          },1000)
        }
        // TencentCloudChat.TYPES.KICKED_OUT_MULT_ACCOUNT(Web端，同一账号，多页面登录被踢)
        // TencentCloudChat.TYPES.KICKED_OUT_MULT_DEVICE(同一账号，多端登录被踢)
        // TencentCloudChat.TYPES.KICKED_OUT_USERSIG_EXPIRED(签名过期)
        // TencentCloudChat.TYPES.KICKED_OUT_REST_API(REST API kick 接口踢出)
      });

    },
    showCamera() {
      this.clickNum = 2;
      this.hideshow = true;
      this.choseCamShow = true;
    },
    choseCamerBtn (){
      this.trtc.stopLocalVideo();
      this.trtc.stopLocalAudio();
      console.log(this.cameraId)
      console.log(this.micId)
      $("#livezhezhao1").hide();
      $("#cameraChoseDiv1").hide();
      if(this.clickNum == 1){       
        this.joinClassRoom();        
      }
    },
    choseCamerBtn2() {
      $("#livezhezhao2").hide();
      $("#cameraChoseDiv2").hide();  
    },
    joinClassRoom (){
      this.trtc.enterRoom({ roomId: Number(this.roomId), scene:'rtc', sdkAppId: Number(this.sdkAppId), userId: this.account, userSig: this.userSig }).then(()=> {
        this.installEventHandlers();
        this.startGetAudioLevel();
        if(this.clickNum == 1){
          this.startRTC(); //自动开启直播            
          this.clickNum = 2;  
        }
        
      }).catch((error)=> {
        console.error('进房失败 ' + error);
      });
    },
    // 启动推流(推摄像头)
    startRTC() {
      var localVideoWrapEl = document.getElementById('local_video');
      if (!localVideoWrapEl) {
        localVideoWrapEl = document.createElement('div');
        localVideoWrapEl.id = 'local_video';
        document.querySelector("#video_wrap").prepend(localVideoWrapEl);
      }
      const view = 'local_video';
      this.trtc.startLocalVideo({
        view,
        option: {
          cameraId: this.cameraId
        }
      }).then(() => { 
        this.sendCustomGroupMsg(null, 'iamteacher|'+ this.account +'');
        this.isPushing = 1;
        this.isPushCamera = true; // 正在推摄像头
        this.enableMic = true;
        this.showTip('推流成功');
        this.toggleMic();
        console.log("发布本地视频成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
      });

    },
    /**
     * 麦克风开关
     */
    toggleMic() {
      if(this.enableMic){
        this.trtc.stopLocalAudio();
      }else {
        this.trtc.startLocalAudio();
      } 
    },
    /**
     * 发送消息
     */
    sendCustomGroupMsg(toUser, todo) {
      this.imMsg.common.toUser = toUser;
      this.imMsg.custom.data = todo;
      // console.log()
      if (this.imMsg.common.toUser) { // C2C 自定义
        // this.tic.sendCustomMessage(this.imMsg.common.toUser, this.imMsg.custom.data, function (res) {
        //   console.log('===sendCustomGroupMsg:', res);
        // });
      } else { //群组 自定义
        // 发送群消息
        let message = this.chat.createTextMessage({
          to: this.roomId,
          conversationType: TencentCloudChat.TYPES.CONV_GROUP,        
          payload: {
            text: this.imMsg.custom.data
          },
          // 支持群消息已读回执功能，如果您发消息需要已读回执，需购买旗舰版套餐，并且创建消息时将 needReadReceipt 设置为 true
          needReadReceipt: true
        });
        // 发送消息
        let promise = this.chat.sendMessage(message);
        promise.then(function(imResponse) {
          // 发送成功
          // console.log(imResponse);
        }).catch(function(imError) {
          // 发送失败
          console.warn('sendMessage error:', imError);
        });


      }
    },
    messageShow(){
      this.messageContent = '';
      $(".livezhezhao").css("display","block");
      $(".questionDiv").css("display","block");
    },
    changeDevice(){
      $("#livezhezhao2").show();
      $("#cameraChoseDiv2").show();
      this.getCameraDevices(1); 
    },
    goBack(){
      this.trtc.stopLocalVideo();
      this.trtc.stopLocalAudio();
      this.$router.push('/classList');  
    },
    cancleSend(){
      $(".livezhezhao").css("display","none");
      $(".questionDiv").css("display","none");
    },
    sendSure(){ //发送信息
      if(!this.messageContent){
        this.$refs.childAlert.show("内容不能为空");
        return false;
      }
      let message = this.nickName  +"|"+ this.messageContent;
      this.sendCustomGroupMsg(null, 'xwmessageContent|'+ message +'');  
      $(".livezhezhao").css("display","none");
      $(".questionDiv").css("display","none");  

    },
    fullscreen(){
      // let teaDivId = localStorage.getItem('teaUserId');
      // let eveLm = $("div[name='"+teaDivId+"']").find('video').eq(0)[0];
      let eveLm = document.getElementById(localStorage.getItem('teaUserId'));

      var ele = document.documentElement;
      if (ele.requestFullscreen) {
          eveLm.requestFullscreen();
      } else if (ele.mozRequestFullScreen) {
          // ele .mozRequestFullScreen();
          eveLm.mozRequestFullScreen();
      } else if (ele.webkitRequestFullScreen) {
          // ele .webkitRequestFullScreen();
          eveLm.webkitRequestFullScreen();
      }
      
      eveLm.onfullscreenchange = (event)=>{
        this.zhezhaoIndex = (this.zhezhaoIndex==1)?0:1;
      }
    },

     /**
     * 结束推流
     */
    stopPush(callback) {
      if (this.isPushing) {
        this.trtc.updateLocalVideo({ publish:false }).then(() => {
          // 取消发布本地流成功
          this.isPushing = 0;
          document.getElementById('local_video').remove();
          if (Object.prototype.toString.call(callback) === '[object Function]') {
            callback();
          }
        });
      }

    },
    ikonwBtn() {
      this.questionList = [];
      this.hideshow = false;
      this.questionShow = false;
    },
    // 点击下课按钮
    stopLive() {
      this.hideshow = true;
      this.xkShow = true;
    },
    closeClass(){ //取消下课
      this.hideshow = false;
      this.xkShow = false;
    },
    stopClass(){ //下课弹窗     
      this.closeCourseView();
      this.hideshow = false;
      this.xkShow = false;
    },
    // 结束直播接口
    closeCourseView() {
      this.$refs.childLoading.show();
      this.axios({
        url:  this.path + "api/tea/closeCourseView",
        method:"post",
        params: {
          "account": this.account,   
          "teacherId": this.teacherId,   
          "courseId": this.courseId,   
          "connectTime": '',   
          "access_token": this.token 
        }
      }).then( (response)=> {
          let sessDta = response.data;
          this.$refs.childLoading.hide();
          if(sessDta.code == 200){
            this.sendCustomGroupMsg(null, 'stopLive');
            this.$refs.childAlert.show("课程结束");
            // this.stopPush();
            this.trtc.stopLocalVideo();
            this.trtc.stopLocalAudio();
            setTimeout(()=>{
               this.$router.push('/classList');  
            },2000)

          }else {
            this.$refs.childAlert.show(sessDta.msg);
          }
          
      }).catch( (error)=> {
          this.$refs.childLoading.hide();
          this.$refs.childAlert.show("网络异常");
      });


    
    },
    /**
     * 枚举摄像头
     */
    getCameraDevices(ctype) {

      if(ctype==1){
        TRTC.getCameraList().then(devices => {
          this.devices.camera = devices;
        }); 
        TRTC.getMicrophoneList().then(devices => {
          this.devices.mic = devices;
        });

      }else{
        this.jtestNum++;
        TRTC.getCameraList().then(devices => {
          if(!devices[0]['deviceId']){
            if(this.jtestNum>=20000){
              $("#jcWord").text('未检测到视频设备');
              return false;
            }
            $("#jcWord").text('检测视频设备中...');
            this.getCameraDevices();
          }else{
            $("#jcWord").text('检测成功');
            this.devices.camera = devices;
            this.cameraId = devices[0].deviceId;
            //预览
            this.viewLocalVideo();

          }
        });
      }


    },
    viewLocalVideo(){
      // 只预览摄像头画面、不发布。可用于做摄像头测试。
      const config = {
        view: document.getElementById('testVideo'), // 在 DOM 中的 elementId 为 localVideo 的标签上预览视频。
        publish: false, // 不发布摄像头
        option: {
          cameraId: this.cameraId
        }
      }
      this.trtc.startLocalVideo(config).then(() => { 
        this.showTip('本地流获取成功');
        console.log("发布本地视频成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
      }).catch(()=>{
        this.cameraId = this.devices.camera[1].deviceId;
        this.viewLocalVideo();
      });

    },
    changeCamer(){
      this.trtc.updateLocalVideo({ option: { cameraId: this.cameraId }}).then(() => { 
        console.log("切换摄像头成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
      });  
      
    },  
    changeCamerTest(){
      // 只预览摄像头画面、不发布。可用于做摄像头测试。
      const config = {
        view: document.getElementById('testVideo'), // 在 DOM 中的 elementId 为 localVideo 的标签上预览视频。
        publish: false, // 不发布摄像头
        option: {
          cameraId: this.cameraId
        }
      }
      this.trtc.updateLocalVideo(config).then(() => { 
        console.log("切换摄像头成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
      });
    },
    /**
     * 枚举麦克风
     */
    getMicDevices() {
      this.jtestNum++;
      TRTC.getMicrophoneList().then(devices => {
        console.log(devices)
        if(devices[0]['deviceId']){
          $("#jcWord").text('检测成功');
          this.devices.mic = devices;
          this.micId = devices[0].deviceId;
          // 如下是测试麦克风音量的代码示例，可用于麦克风音量检测。
          this.trtc.enableAudioVolumeEvaluation(1000);
          this.trtc.on(TRTC.EVENT.AUDIO_VOLUME, event => {
            event.result.forEach(({ userId, volume }) => {
                if (volume >= 1) {
                  $("#auylSpan").text(volume.toFixed(0)); 
                }
            })
           });

          // 测试麦克风无需发布音频
          this.trtc.startLocalAudio({ publish: false });
          // 测试完毕后，关闭麦克风
          // this.trtc.stopLocalAudio();

          var audioSmallDiv = '<div class="auyl_div" id="bigAudioDiv">您的音量：<span id="auylSpan"></span></div>';
          $("#testVideo").append($(audioSmallDiv));


        }else{
          if(this.jtestNum>=20){
            $("#jcWord").text('未检测到音频设备');
            return false;
          }
          $("#jcWord").text('检测音频设备中...');
          this.getMicDevices();
        }

      });


    },
    changeMic(){
      this.trtc.updateLocalAudio({ option: { microphoneId: this.micId }}).then(() => { 
        console.log("切换麦克风成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
      }); 
    },
    changeMicTest(){
      this.trtc.updateLocalAudio({ option: { microphoneId: this.micId }}).then(() => { 
        console.log("切换麦克风成功。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。。")
      });

    },
    initTRTC() {
      if (this.trtc) return;
      this.trtc = TRTC.create();
    },

    async enterRoom() {
      this.roomStatus = 'entering';
      this.initTRTC();
      try {
        await this.trtc.enterRoom({
          roomId: this.roomId,
          sdkAppId: parseInt(this.sdkAppId, 10),
          userId: this.userId,
          userSig: this.userSig,
        });
        this.roomStatus = 'entered';

        this.installEventHandlers();
        this.startGetAudioLevel();
        this.reportSuccessEvent('enterRoom');
      } catch (error) {
        this.roomStatus = 'exited';
        console.error('enterRoom room failed', error);
        this.reportFailedEvent('enterRoom', error);
        throw error;
      }
    },

    async handleStartLocalAudio() {
      this.micStatus = 'starting';
      this.initTRTC();
      try {
        await this.trtc.startLocalAudio({
          option: {
            microphoneId: this.microphoneId,
          },
        });
        this.isMutedAudio = false;
        this.micStatus = 'started';
        this.reportSuccessEvent('startLocalAudio');
      } catch (error) {
        this.micStatus = 'stopped';
        this.reportFailedEvent('startLocalAudio', error.message);
        throw error;
      }
    },

    async handleStopLocalAudio() {
      if (this.micStatus !== 'started') {
        return;
      }
      this.micStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.stopLocalAudio();
        this.micStatus = 'stopped';
        this.reportSuccessEvent('stopLocalAudio');
      } catch (error) {
        this.micStatus = 'started';
        this.reportFailedEvent('stopLocalAudio', error.message);
        throw error;
      }
    },

    async handleStartLocalVideo() {
      this.camStatus = 'starting';
      this.initTRTC();
      try {
        await this.trtc.startLocalVideo({
          view: 'local',
          option: {
            cameraId: this.cameraId,
            profile: '480p',
          },
        });
        this.camStatus = 'started';
        this.isMutedVideo = false;
        this.reportSuccessEvent('startLocalVideo');
      } catch (error) {
        this.camStatus = 'stopped';
        this.reportFailedEvent('startLocalVideo', error.message);
        throw error;
      }
    },

    async handleStopLocalVideo() {
      if (this.camStatus !== 'started') {
        return;
      }
      this.camStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.stopLocalVideo();
        this.camStatus = 'stopped';
        this.reportSuccessEvent('stopLocalVideo');
      } catch (error) {
        this.camStatus = 'started';
        this.reportFailedEvent('stopLocalVideo', error.message);
        throw error;
      }
    },

    async exitRoom() {
      if (this.roomStatus !== 'entered') {
        return;
      }
      this.roomStatus = 'exiting';
      this.stopGetAudioLevel();

      try {
        await this.trtc.exitRoom();
        this.roomStatus = 'exited';
        this.remoteUsersViews = [];
        this.uninstallEventHandlers();
        this.reportSuccessEvent('exitRoom');
      } catch (error) {
        this.roomStatus = 'entered';
        this.reportFailedEvent('exitRoom', error);
        throw error;
      }

      if (this.micStatus === 'started') this.handleStopLocalAudio();
      if (this.camStatus === 'started') this.handleStopLocalVideo();
      if (this.shareStatus === 'shared') this.handleStopScreenShare();
    },

    async muteVideo() {
      try {
        await this.trtc.updateLocalVideo({ mute: true });
        this.isMutedVideo = true;
      } catch (error) {
      }
    },

    async muteAudio() {
      try {
        await this.trtc.updateLocalAudio({ mute: true });
        this.isMutedAudio = true;
      } catch (error) {
      }
    },

    async unmuteVideo() {
      try {
        await this.trtc.updateLocalVideo({ mute: false });
        this.isMutedVideo = false;
      } catch (error) {
      }
    },

    async unmuteAudio() {
      try {
        await this.trtc.updateLocalAudio({ mute: false });
        this.isMutedAudio = false;
      } catch (error) {
      }
    },

    async switchDevice(type, deviceId) {
      try {
        if (type === 'video' && this.camStatus === 'started') {
          await this.trtc.updateLocalVideo({
            option: { cameraId: deviceId },
          });
        }
        if (type === 'audio' && this.micStatus === 'started') {
          await this.trtc.updateLocalAudio({
            option: { microphoneId: deviceId },
          });
        }
      } catch (error) {
        console.error('switchDevice failed', error);
      }
    },

    startGetAudioLevel() {
      this.trtc.on(TRTC.EVENT.AUDIO_VOLUME, (event) => {
        event.result.forEach(({ userId, volume }) => {
          const isMe = userId === ''; // 当 userId 为空串时，代表本地麦克风音量。
          if (isMe) {
            // console.log(`my volume: ${volume}`);
          } else {
            // console.log(`user: ${userId} volume: ${volume}`);
          }
        });
      });
      this.trtc.enableAudioVolumeEvaluation(2000);
    },

    stopGetAudioLevel() {
      this.trtc && this.trtc.enableAudioVolumeEvaluation(-1);
    },

    installEventHandlers() {
      this.trtc.on(TRTC.EVENT.ERROR, this.handleError);
      this.trtc.on(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter);
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, (event)=>{
        // this.sendCustomGroupMsg(null, 'whoisteacher'); 

        const userId = event.userId;
        if(localStorage.getItem('teaUserId') == userId){
          this.showTip('老师进入教室');
        }else {
          this.showTip(userId + '进入教室');
        }
        
        const streamType = event.streamType;
        // const remoteStream = window.remoteStream = event.stream;
        // 远端流订阅成功，在HTML页面中创建一个<video>标签，假设该标签ID为‘remote-video-view’
        // 播放该远端流
        let remoteVideoWrapEl = document.createElement('div');
        remoteVideoWrapEl.id = userId;
        remoteVideoWrapEl.name = userId;
        remoteVideoWrapEl.style.marginLeft = '10px';
        
        document.querySelector("#video_wrap").append(remoteVideoWrapEl);
        // remoteStream.play(remoteVideoWrapEl);
        this.trtc.startRemoteVideo({userId, streamType, view: userId});
        if(localStorage.getItem('teaUserId')== userId){
          // $(remoteVideoWrapEl).addClass("big_tea");
          let teaDiv = document.getElementById(localStorage.getItem('teaUserId'));
          teaDiv.className = 'big_tea';
          teaDiv.style.height = 'calc(100vh - 110px)';
          this.zwtIndex = 1;
          // teaDiv.parentNode.style.width = '0';
        }

        
        // let stuDivs = document.querySelector("#video_wrap").childNodes;
        // let stuIndex = 0;
        // setTimeout(()=>{
        //   for(let i=0;i<stuDivs.length;i++){
        //     if(stuDivs[i].nodeType==1 && stuDivs[i].nodeName=='DIV' && stuDivs[i].id!='local_video'){
        //       stuDivs[i].childNodes[0].setAttribute('name', this.inUserIds[stuIndex]);
        //       stuIndex++;
        //     }
        //   }
        //   document.getElementById('local_video').setAttribute('name', this.account);
        //   alert(localStorage.getItem('teaUserId'))
        //   if(localStorage.getItem('teaUserId')){
        //     let teaDiv = document.getElementsByName(localStorage.getItem('teaUserId'))[0];
        //     teaDiv.className = 'big_tea';
        //     teaDiv.style.height = 'calc(100vh - 110px)';
        //     this.zwtIndex = 1;
        //     teaDiv.parentNode.style.width = '0';
        //     // teaDiv.style.width = 'calc((100vh - 170px)/9*16)';  http://localhost:8080/studentSS/stuLive.html
        //   }
        // }, 1000);

      });
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, (event)=>{
        const userId = event.userId;
        if(localStorage.getItem('teaUserId') == userId){
          this.showTip('老师离开教室');
          this.zwtIndex = 2;
        }else {
          this.showTip(userId + '离开教室');
        }

      });
      this.trtc.on(TRTC.EVENT.KICKED_OUT, ()=>{
        const userId = event.userId;
        if(localStorage.getItem('teaUserId') == userId){
          this.showTip('老师离开教室');
          this.zwtIndex = 2;
        }else {
          this.showTip(userId + '离开教室');
        }

      });
      this.trtc.on(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit);
      this.trtc.on(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, this.handleRemoteAudioAvailable);
      this.trtc.on(TRTC.EVENT.SCREEN_SHARE_STOPPED, this.handleScreenShareStopped);
      this.trtc.on(TRTC.EVENT.NETWORK_QUALITY, event => {
        // console.log(`network-quality, uplinkNetworkQuality:${event.uplinkNetworkQuality}, downlinkNetworkQuality: ${event.downlinkNetworkQuality}`)
        // console.log(`uplink rtt:${event.uplinkRTT} loss:${event.uplinkLoss}`)
        // console.log(`downlink rtt:${event.downlinkRTT} loss:${event.downlinkLoss}`)
      })
      this.trtc.on(TRTC.CONNECTION_STATE_CHANGED, event => {
        const prevState = event.prevState;
        const curState = event.state;
        console.log(event)
        if(curState == "DISCONNECTED"){
          this.tsText = "连接断开";
          this.hideshow = true;
          this.tsDivShow = true; 
        }
        if(curState == "CONNECTING"){
          this.tsText = "正在连接中。。。";
          this.hideshow = true;
          this.tsDivShow = true; 
        }
        if(curState == "RECONNECTING"){
          this.tsText = "自动重连中。。。";
          this.hideshow = true;
          this.tsDivShow = true; 
        }
        if(curState == "CONNECTED"){
          this.tsText = "已连接";
          this.hideshow = true;
          this.tsDivShow = true; 
          setTimeout(()=>{
            this.hideshow = false;
            this.tsDivShow = false; 
          },2000)
        }

      });
    },

    uninstallEventHandlers() {
      this.trtc.off(TRTC.EVENT.ERROR, this.handleError);
      this.trtc.off(TRTC.EVENT.KICKED_OUT, this.handleKickedOut);
      this.trtc.off(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter);
      this.trtc.off(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit);
      this.trtc.off(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, this.handleRemoteVideoAvailable);
      this.trtc.off(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, this.handleRemoteVideoUnavailable);
      this.trtc.off(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, this.handleRemoteAudioUnavailable);
      this.trtc.off(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, this.handleRemoteAudioAvailable);
      this.trtc.off(TRTC.EVENT.SCREEN_SHARE_STOPPED, this.handleScreenShareStopped);
    },

    handleError(error) {
      alert(error);
    },

    async handleKickedOut(event) {
      console.log(event)
      // this.showTip('用户异地登录，请重新登录'); 
      // this.trtc.destroy();
      // setTimeout(()=>{
      //     this.$router.push('/index');  
      // },1000)
      this.trtc = null;
      await this.exitRoom();
    },

    handleRemoteUserEnter(event) {
      const { userId } = event;
    },

    handleRemoteUserExit(event) {
    },

    handleRemoteVideoAvailable(event) {

      const { userId, streamType } = event;
      try {
        if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
          this.remoteUsersViews.push(`${userId}_main`);
          this.$nextTick(async () => {
            await this.trtc.startRemoteVideo({ userId, streamType, view: `${userId}_main` });
          });
        } else {
          this.remoteUsersViews.push(`${userId}_screen`);
          this.$nextTick(async () => {
            await this.trtc.startRemoteVideo({ userId, streamType, view: `${userId}_screen` });
          });
        }
      } catch (error) {
      }
    },

    handleRemoteVideoUnavailable(event) {
      const { streamType } = event;
      this.trtc.stopRemoteVideo({ userId: event.userId, streamType });
      this.showTip(event.userId + '离开教室');  
      if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
        this.remoteUsersViews = this.remoteUsersViews.filter(userId => userId !== `${event.userId}_main`);
      } else {
        this.remoteUsersViews = this.remoteUsersViews.filter(userId => userId !== `${event.userId}_screen`);
      }
    },

    handleRemoteAudioUnavailable(event) {
    },

    handleRemoteAudioAvailable(event) {
    },
    handleScreenShareStopped() {
      this.shareStatus = 'stopped';
    },
    gojcMai(){
      $("#shesDiv").hide();
      $("#maisDiv").show();
      $("#videoOKBtn").hide();
      $("#deviseOKBtn").show();
      $("#jcWord").text("检测音频设备中...");
      this.trtc.stopLocalVideo();
      this.isBlack = true;
      $("#testVideo").html('');
      this.getMicDevices();
      this.jtestNum = 0;
    },

  }
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app_box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #222736;
  }

  .title {
    margin: 10px 0;
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .promption {
    color: #A0A0A0
  }

  .im-log-box {
    width: 100%;
    height: 100%;
    /*border: 1px solid #999;*/
    margin: 0px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .im-log-box li {
    margin: 2px 5px;
    font-size: 14px;
    color: #666;
  }

  #video_wrap {
    height: 100px;
    display: flex;
  }

  #video_wrap>div {
    width: 134px;
    height: 100%;
    box-flex: 1;
    -webkit-box-flex: 1;
  }

  #paint_box {
    position: relative;
    width: 100%;
    /*height: 450px;*/
    height: 100%;
    border: 1px solid #888;
    overflow: hidden;
    box-sizing: content-box;
  }

  .el-header,
  .el-footer {
    padding: 0 !important;
  }

  .el-main {
    padding: 0 !important;
    overflow: hidden !important;
    height: 100%;
  }

  .el-container {
    height: 100%;
    overflow: hidden;
  }

  .el-tabs__header {
    margin: 0;
  }

  .el-icon-arrow-left,
  .el-icon-arrow-right {
    font-size: 20px;
  }

  .version-info {
    font-size: 14px;
    color: #606266;
  }
  .el-input {
    width: 180px;
    display: inline-block;
  }
  .local_video{display:flex;}
  #video_wrap .big_tea{position:absolute !important;top:110px;left:0;right:0;bottom:0;z-index:1;margin:0 auto;width: 100%;}
  .img_she{position:relative;width:30px;height:30px;z-index:2;top:-30px;}
  .img_she_qita{position:relative;width:30px;height:30px;z-index:2;top:70px;}
  .sqbb{position:static !important;top:0 !important;left:0 !important;bottom:0 !important;width:100%;height:100%;}
    .livezhezhao{background-color:#000;position:fixed;z-index:99;left:0;top:0;right:0;bottom:0;width:100%;min-width:1300px;height:100%;opacity:0.5;filter: alpha(opacity=50);-moz-opacity: 0.5;}
  .cameraChoseDiv {
    width: 400px;
    height: 430px;
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    /*display: none;*/
    z-index: 100;
    padding: 20px 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .tsDiv {
    width: 200px;
    height: 40px;
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    z-index: 100;
    line-height: 40px;
    text-align: center;
    box-sizing: border-box;
    border-radius: 4px;
    color: #333;
  }
  .choseDiv {
    margin-bottom: 20px;
    width: 100%;
    line-height: 30px;
    height: 30px;
  }
  .choseDiv span {
    font-size: 12px;
    color: #333;
    display: inline-block;
    margin-right: 10px;
  }
  .choseDiv select {
    width: 300px;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    text-align: right;
    /*direction: rtl;*/
  }
  .choseDiv select option {
    /*direction: ltr;*/
  }
  .cameraBtn {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #F5CA1F;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    margin: 0 auto;
    cursor: pointer;
  }
  .lmBtn {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #F5CA1F;
    color: #fff;
    font-size: 12px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 4px;
    z-index: 999;
    cursor: pointer;
    display: none;
  }
  .lmBtn.showMic {
    display: block;
  }
  #video_wrap video {
    /*position:static !important;*/
    transform: rotateY(0) !important;
    object-fit: contain !important;

  }
  .zhanwei{
    position: absolute;
    margin: auto;
    width: 150px;
    height: 150px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 50;
    text-align:center;
    font-size:16px;color:#FFF;
  }
  .full-screen{width:20px;height:20px;background:url('../assets/teaImg/fullscreen.png') no-repeat center center;
    position:absolute;right:20px;bottom:20px;z-index:50;cursor:pointer;background-size:100%;
  }
  .go_back{width:70px;height:30px;text-align:center;line-height:30px;color:#FFF;border-radius:6px;background:#2F5AFF;
    position:absolute;top:20px;left:20px;cursor:pointer;
  }
  .auyl_div{width:200px;height:20px;font-size:14px;color:#FFF;position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;text-align:center;z-index: 999;}
  .questionDiv {
    width: 400px;
    height: 210px;
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    z-index: 100;
    line-height: 30px;
    /* text-align: center; */
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 4px;
    color: #333;
  }
  .questionDiv textarea{ 
      resize:none;box-sizing:border-box;border:none;border:1px solid #C9C9C9;
      border-radius:4px;padding-left:10px;outline:none;font-family:Microsoft Yahei;
      width: 100%; height: 90px; line-height: 30px;
  }
  .questionBtnDiv {
    width: 100%;
    text-align: center;
    margin: 10px auto;
  }
  .sendMessage {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #07ADF5;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 5px;
    display: inline-block;
  }
  .cancleMessage {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #F5CA1F;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 5px;
    display: inline-block;
  }
  .bgcBlick {
    background-color: #333;
  }


</style>
