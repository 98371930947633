<template>
  <div class="hello" style="width: 100%; height: 100%">
    <div class="lookBackwk">
      <div class="lookBackDiv">
        <div class="prism-player" id="player-con"></div>
      </div>

      <div class="lookUrlDiv">
        <div class="sphkName">{{ courseName }}</div>
        <div class="sphkTime">{{ beginTime.slice(0,16) }} - {{ endTime.slice(0,16) }}</div>

        <div class="splbListDiv">
          <div class="splbTitle">视频列表</div>

          <div class="splbList" id="splbList">
            <span v-for="(v,i) in videoList" @click="lookBtn(v.mediaUrl,v.id,i)" :key="v.id" :class="{'check':videoId == v.id}">{{ i+1 }}</span>
          </div>
        </div>

        <div class="hksmDiv">
          <div class="splbTitle">说明</div>
          <div class="smContent">
            1. 回看转码需要一定的时间，一般在课程结束后2小时会转码完成。
          </div>
          <div class="smContent">
            2.
            如果在回看过程中发现视频片段缺失，请耐心等待，稍后刷新当前页面即可。
          </div>
        </div>
      </div>
    </div>

    <ssAlert ref="childAlert"></ssAlert>
    <ssLoading ref="childLoading"></ssLoading>
  </div>
</template>

<script>
import $ from "jquery";
import ssAlert from "@/components/commonVue/Alert";
import ssLoading from "@/components/commonVue/Loading";

export default {
  name: "coursePlay",
  components: {
    ssAlert,
    ssLoading,
  },
  data() {
    return {
      path: this.newCommon["publicDef"]["newPath"],
      player: "",
      palyNum: 0,
      courseName: '',
      courseId: '',
      beginTime: '',
      endTime: '',
      videoId: '',
      videoList: [],
    };
  },
  props: {},
  mounted() {
    this.courseName = this.$route.query.courseName;;
    this.courseId = this.$route.query.courseId;;
    this.beginTime = localStorage.getItem("beginTime");
    this.endTime = localStorage.getItem("endTime");
    document.title = this.courseName;
    this.getLookBackUrl();

    

  },
  methods: {
    seekBtn(){
      this.player.seek(500);
    },
    stopLive(){
      let state = this.player.getStatus();
      console.log(state)
      if(state == 'ready' || state == 'playing'){
        this.player.pause();
      }

      if(state == 'pause'){
        this.player.play();
      }

    },
    showTip(title, time) {
      this.$message({
        showClose: true,
        message: title,
        type: "success",
      });
    },
    getLookBackUrl() {
      this.$refs.childLoading.show();
      this.axios({
        url:  this.path + "api/open/getRecordListById",
        method:"post",
        params: {
          "courseId": this.courseId
        }
      }).then( (response)=> {
          this.$refs.childLoading.hide();
          let sessDta = response.data;
          if(sessDta.code == 200){
            if(sessDta["data"].length<=0){
                this.$refs.childAlert.show("暂无回看",()=>{
                  this.$router.push('/classList');	
                });
                return false;
            }

            let firstUrl = sessDta["data"][0]["mediaUrl"];
            this.videoId = sessDta["data"][0]["id"];
            this.lookVideo(firstUrl);

            this.videoList = sessDta["data"];

            
          }else {
            this.$refs.childAlert.show(sessDta.msg);
          }
          
          
      }).catch( ()=> {
          this.$refs.childLoading.hide();
          this.$refs.childAlert.show("网络异常");
      });
      
    },
    lookVideo(videoUrl) {
      // 观看视频
      var videoHeight = document.documentElement.clientHeight;
      var heightPx = videoHeight + "px";
      this.player = new Aliplayer(
        {
          id: "player-con",
          source: videoUrl,
          width: "100%",
          height: heightPx,
          cover: "http://static.xw100.com/live/img/Bitmap.png",
          autoplay: true,
          isLive: false,
          rePlay: false,
          playsinline: true,
          preload: true,
          language: "zh-cn",
          controlBarVisibility: "hover",
          useH5Prism: true,
          skinLayout: [
            {
              name: "bigPlayButton",
              align: "blabs",
              x: 30,
              y: 80,
            },
            {
              name: "H5Loading",
              align: "cc",
            },
            {
              name: "errorDisplay",
              align: "tlabs",
              x: 0,
              y: 0,
            },
            {
              name: "infoDisplay",
            },
            {
              name: "tooltip",
              align: "blabs",
              x: 0,
              y: 56,
            },
            {
              name: "thumbnail",
            },
            {
              name: "controlBar",
              align: "blabs",
              x: 0,
              y: 0,
              children: [
                {
                  name: "progress",
                  align: "blabs",
                  x: 0,
                  y: 44,
                },
                {
                  name: "playButton",
                  align: "tl",
                  x: 15,
                  y: 12,
                },
                {
                  name: "timeDisplay",
                  align: "tl",
                  x: 10,
                  y: 7,
                },
                {
                  name: "fullScreenButton",
                  align: "tr",
                  x: 10,
                  y: 12,
                },
                {
                  name: "setting",
                  align: "tr",
                  x: 15,
                  y: 12,
                },
                {
                  name: "volume",
                  align: "tr",
                  x: 5,
                  y: 10,
                },
              ],
            },
          ],
        },
         (player)=> {
          console.log("The player is created");
        }
      );

      this.player.play();
      this.player.on("ended", this.endedHandle);
      
    },
    endedHandle(){
      let newUrlList = $("#splbList>span");
      this.palyNum++;
      if(this.palyNum>=newUrlList.length){

      }else {
        newUrlList.eq(this.palyNum).trigger("click");
      }

    },
    lookBtn(mediaUrl,id,i){
      if(this.videoId == id){

      }else {
        this.videoId = id;
        this.palyNum = i;
        this.player.dispose(); 
        this.player = '';
        this.lookVideo(mediaUrl);
      }

    },

  },
};
</script>

<style>
/*回看*/
.lookBackwk {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}
.lookBackDiv {
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  right: 400px;
  bottom: 0;
  box-sizing: border-box;
}
.lookUrlDiv {
  position: absolute;
  width: 400px;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: #1e1e24;
  color: #fff;
  text-align: left;
}
.sphkName {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 30px;
  max-height: 90px;
  overflow: hidden;
}
.sphkTime {
  font-size: 14px;
  margin: 20px auto;
}
.splbListDiv {
  width: 100%;
  max-height: 200px;
  overflow: auto;
  padding: 20px 0;
  border-top: 1px dashed #e3e3e3;
  border-bottom: 1px dashed #e3e3e3;
}
.splbTitle {
  padding-left: 10px;
  box-sizing: border-box;
  border-left: 4px solid #3792ff;
  color: #3792ff;
  font-size: 16px;
}
.splbList {
  margin: 10px auto;
}
.splbList span {
  display: inline-block;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #3792ff;
  color: #3792ff;
  text-align: center;
  line-height: 36px;
  margin: 0 5px;
  cursor: pointer;
  margin-top: 10px;
}
.splbList span.check {
  background-color: #3792ff;
  color: #fff;
}
.hksmDiv {
  margin: 20px auto;
}
.smContent {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}
</style>
