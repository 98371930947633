import Vue from 'vue'
import Router from 'vue-router'
import index from '@/components/index'
import classList from '@/components/classList'
import studentLive from '@/components/studentLive'
import coursePlay from '@/components/coursePlay'



Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',                              //首页
      component: index,
      children: [
        {
          path: '/index',                               //首页
          component: index
        }     
        
      ]
   },
   {
    path: '/classList',                  //课程列表
    component: classList
   },
   {
    path: '/studentLive',                  //老师直播
    component: studentLive
   },
   {
    path: '/coursePlay',                  //视频回看
    component: coursePlay
   },

   
   


  ]
})
